import i18n from "../../../../locales";
import { SagaIterator } from "redux-saga";
import { requester } from "../../../../libs/requester";
import { put, call, takeLatest } from "redux-saga/effects";
import { links } from "../../../../utils/links";
import {
  saveIsRadioBot,
  saveProductCharacteristics,
  setProductCharacteristicsLoading,
} from "../../repository/actions";
import { createNotification } from "../../../../utils/createNotification";

function* getProductCharacteristicsWorker({ payload }: any): SagaIterator {
  try {
    yield put(setProductCharacteristicsLoading(true));
    const { data, isError, message } = yield call(
      requester.get,
      `${links.productCharacteristics}/${payload}`
    );
    if (isError) {
      createNotification({
        title: i18n.t("error"),
        message,
        type: "danger",
      });
    } else {
      yield put(saveProductCharacteristics(data.data)); 
      yield put(saveIsRadioBot(data.isPartOfTgRadioBot)); 
    }
  } catch (error) {
    console.error("getProductCharacteristicsWorker: ", error);
  } finally {
    yield put(setProductCharacteristicsLoading(false));
  }
}

export function* getProductCharacteristicsWatcher() {
  yield takeLatest(
    "@saga/GET_PRODUCT_CHARACTERISTICS",
    getProductCharacteristicsWorker
  );
}
