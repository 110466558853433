import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getSingleProduct } from "../../store/products/useCases/getSingleProduct/action";
import { getProductDescription } from "../../store/products/useCases/getDescription/action";
import SingleProduct from "../../ui-kit/singleProduct/singleProduct";
import { saveIsRadioBot, saveSingleProduct } from "../../store/products/repository/actions";

const SingleProductPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(getSingleProduct(id));
      dispatch(getProductDescription(id));
    }
    return () => {
      dispatch(saveSingleProduct(null));
      dispatch(saveIsRadioBot(false));
    }
  }, [id]);

  return <SingleProduct />;
};

export default SingleProductPage;
