import i18n from "i18next";
import { LOCAL_STORAGE, DEFAULT_LANGUAGE } from "../constants";

const resources = {
  en: {
    translation: {
      days: 'days',
      hours: 'hours',
      minutes: 'minutes',
      seconds: 'seconds',
      left: 'left',
      leftToEnd: 'Until the end of the promotion',
      day1: 'days',
      day2: 'days',
      day3: 'days',
      finished: 'finished',
      promoDetails: "Detailes of the promotion",
      promoFrom: "The promotion is valid from",
      promoTo: "to",
      promoFinished: "Finished",
      emptyStateTitle: "The list is empty",
      emptyStateDesc: "Sorry, there are no promotions available at the moment. Please try again later or contact our support team for more information.",
      productsCatalog: "Products catalog",
      catalog: "Catalog",
      news: "News",
      warrantyConditions: "Public offer agreement",
      aboutUs: "About us",
      support: "Support",
      contacts: "Contacts",
      contactUs: "Contact us",
      search: "Search",
      info: "Information",
      allRightsReserved: "All rights reserved",
      followUsOnSocial: "Download Our App",
      promotions: "Promotions",
      promo: "Promo",
      pair: "In a pair",
      popularModels: "Popular models",
      sale: "Sale",
      allProducts: "All products",
      newsAndArticles: "News and articles",
      sortBy: "Sort by",
      ASC: "Low to High",
      DESC: "High to Low",
      promoASC: "Promotion Low to High",
      promoDESC: "Promotion High to Low",
      select: "Select",
      resetFilters: "Reset filters",
      apply: "Apply",
      price: "Price",
      available: "Available",
      notAvailable: "Not available",
      alreadyInCart: "Product already in cart",
      aviableNotify: "Get notified about product availability",
      alreadyNotify:
        "We'll send you a notification as soon as the product is back in stock",
      productCode: "Product code",
      buy: "Buy",
      characteristics: "Characteristics",
      description: "Description",
      reviews: "Reviews",
      writeReview: "Write a review",
      leaveFeedback: "Leave feedback",
      name: "Name",
      openTgBot: "Open telegram bot",
      surname: "Surname",
      email: "Email",
      mark: "Mark",
      message: "Message",
      showInstruction: "Show instruction",
      minimalCount: "Minimum order quantity for this product",
      noReviews: "There are no reviews for this product. You can be the first!",
      goToHomePage: "Go to home page",
      title404: "Page not Found",
      message404: "Sorry, we can’t find the page you’re looking for.",
      queryResult: "Results for the query",
      queryNoResult: "No results for the query",
      noResultMessage1:
        "Unfortunately, we could not find any products matching your search.",
      noResultMessage2: "Please try adjusting your search parameters.",
      searchMinLengthError: "Search query must be at least 2 characters long.",
      login: "Log in",
      register: "Register",
      forgotPass: "Forgot Password",
      pass: "Password",
      emailOrPhone: "Email or Phone number in the format +380",
      continue: "Continue",
      phone: "Phone",
      confirmPass: "Confirm password",
      country: "Country",
      region: "Region",
      terms: "I have read and agree with",
      termsWord: "privacy policy",
      passwordnotSame: "Passwords are not the same",
      passwordLength: "Password should be at least 6 characters long",
      company: "Company",
      yourName: "Your name",
      yourSurname: "Your Surname",
      enterCompany: "Enter company name",
      enter: "Enter",
      error: "Error!",
      chooseCountry: "Please choose a country",
      chooseCity: "Please choose a city",
      chooseWarehouse: "Please choose a Nova Poshta warehouse",
      chooseState: "Please choose a state",
      invalidPhone: "Invalid phone number format",
      confirm: "Confirm",
      messagesIsRead: "All messages have been read!",
      confirmCodeDescr:
        "Please enter the verification code sent to your phone number.",
      confirmCodeDescrEmail:
        "Please enter the verification code sent to email address.",
      enterCode: "Your verification code",
      phoneCode: "Phone code",
      success: "Success!",
      notEmailOrPhone:
        "Unfortunately, you have entered an invalid email or phone number format. Please check the information you entered and try again.",
      resendCode: "Resend code",
      maxResendCount: "Maximum resend count exceeded.",
      codeAgainSend:
        "Code successfully resent! Please check your phone to receive a new confirmation code.",
      codeAgainSendEmail:
        "Code successfully resent! Please check your email to receive a new confirmation code.",
      profile: "Profile",
      favorites: "Selected products",
      cart: "Cart",
      logOut: "Log out",
      yes: "Yes",
      no: "No",
      loginOrRegisterTitle: "Log in to continue",
      loginOrRegister: "Log in or register",
      quantity: "Quantity",
      remove: "Remove",
      send: "Send",
      setArticleRating:
        "Help us enhance our content by providing a rating for this news.",
      emptyFavorites: "No items in your favorites yet.",
      emptyFavorites2:
        " Find a product you love and add it to your favorites for quick access.",
      successAddedFavorite:
        "The item has been successfully added to favorites.",
      successAddedWaiting:
        "The item has been successfully added to waiting list.",
      successDeleteFavorite:
        "The item has been successfully removed from favorites.",
      successDeleteWaiting:
        "The item has been successfully removed from waiting list.",
      successAddedToOrder: "The item has been successfully added to cart.",
      attention: "Attention",
      afterRegisterText:
        "You will be contacted within two business days to clarify the information.",
      ok: "Okay",
      reviewMessage: "Write your review here...",
      reviewSent: "Thank you for your review!",
      reviewSentInfo:
        "After being checked by a moderator, it will be published.",
      cancel: "Сancel",
      orders: "My orders",
      clearCart: "Clear the cart",
      toOrdering: "Order",
      emptyCart1: "You don't have any items in your cart yet.",
      emptyCart2: "Find the products you like and add them to your cart.",
      retailPrice: "Retail price",
      wholesalePrice: "Wholesale price",
      largeWholesalePrice: "Partner",
      partnerPrice: "Partner price",
      specialPrice: "Special price",
      total: "Total",
      emptyOrders1: "You don't have any orders yet.",
      emptyOrders2: "Make your first order to see it here.",
      emptyClosedOrders: "You don't have any closed orders yet.",
      pending: "Pending",
      closed: "Closed",
      piece: "pc.",
      status: "Status",
      comment: "Comment",
      yourComment: "Your comment",
      yourMessage: "Your message",
      yourAddress: "Your address",
      writeUs: "Write us",
      discount: "Discount",
      paymentMethod: "Payment Method",
      fact: "Factual",
      nonFact: "Not factual",
      workHour: "Working hours",
      workHourVal: "Mon-Sat: 9:00-21:00, Sunday: day off.",
      saleDep: "Sales department",
      techDep: "Technical support",
      orderType: "Delivery method",
      delivery: "Delivery of Nova Poshta by courier",
      pickup: "Pickup (Kharkiv city)",
      deliveryToWarehouse: "Delivery to a Nova Poshta warehouse",
      city: "City",
      state: "State",
      warehouse: "Warehouse",
      address: "Address",
      processOrder: "Place an order",
      factPay:
        "Payment upon delivery (without payment deferral). A 5% discount on the cost is provided",
      chooseOrderType: "Choose delivery method",
      warehouseNP: "To a Nova Poshta warehouse",
      courierNP: "By Nova Poshta courier",
      pickupAddress:
        'Kharkiv city, 28B Vashchenkivsky lane, 1st floor, "Avtozvuk" store',
      address2: '28 "B" Vashchenkivskyi lane, Kharkiv, Ukraine',
      pickupText1: "After placing an order",
      pickupStrongText:
        "marked goods are reserved for you for a period of 3 days.",
      pickupText2:
        "At the end of the term, if the goods are not sold, the reservation is canceled and the goods can be sold to another buyer.",
      totalSum: "Total summary",
      addedToOrder: "In the cart",
      currentOrders: "Сurrent orders",
      historyOrders: "Orders history",
      thanksForOrder: "Thank you for your order!",
      order: "Order",
      orderInfo:
        "Our manager will contact you to clarify the details of the order.",
      uah: "UAH",
      грн: "UAH",
      ye: "c.u.",
      change: "Change",
      noChange: "No Data Changes Occurred",
      confirmEmail: "Please Confirm Your Email.",
      confirmEmailText1:
        "We have sent you an email with a confirmation link. Please follow the link to confirm your email.",
      confirmEmailText2:
        "If you haven't received the email, please check your 'Spam' folder or contact our support team.",
      notifications: "Notifications",
      emptyNotifications: "You don't have any notifications yet.",
      emptyNotifyProducts:
        "You haven't added any products to the notification list yet.",
      developed: "Developed",
      promoCode: "Promo Code",
      applied: "applied",
      notifyTitle: "Waiting list",
      downloadPriceList: "Download price list",
      downloadShortPriceList: "Download the shortened price list",
      downloadXML: "Download XML file",
      guiideByChoice: "Guide by choice",
      publicOfferAgreement: "Public offer agreement",
      successConfirmEmail:
        "You have successfully confirmed your email address!",
      failConfirmEmail: "Unfortunately, we couldn't confirm the email address.",
      warehouseAvailable: "Availability in warehouses",
      storage: "Warehouse",
      availability: "Availability",
      allWarehouses: "All warehouses",
      toOrder: "To order 2-3 days",
      noResultByFilter:
        "We're sorry, but we couldn't find any matching products for the filter you selected.",
      errorAuth: "Something went wrong. Contact the support team.",

      this_phone_number_is_already_in_use:
        "This phone number is already in use",
      this_email_is_already_in_use: "This email is already in use",
      this_email_already_in_use: "This email is already in use",
      today_max_phone_code_amount_expired:
        "Today's maximum limit of phone code requests has been exceeded",
      code_is_expite_or_wrong: "Code has expired or entered incorrectly",
      login_or_password_wrong: "Incorrect login or password",
      user_not_found: "User not found",
      please_confirm_your_phone: "Please confirm your phone number.",
      agreeLogout: "Are you sure you want to log out?",
      agreeDelete: "Are you sure you want to delete all products from cart?",
      agreeClearAllMessages: "Would you like to make all messages read?",
      agreeDeleteOne: "Are you sure you want to delete this product?",
      promo_code_not_found:
        "Promo Code Not Found. Please double-check the code you entered and try again",
      promo_code_expired:
        "Oops! The promo code you are trying to use has expired.",
      promo_code_usage_amount_exhausted:
        "Unfortunately, the number of uses of this promo code has been exhausted. Please try another promo code.",
      you_already_use_this_promo_code: "You have already used this promo code.",
      promo_code_start_date_has_not_yet_arrived:
        "Promo code start date has not yet arrived.",
      warrantyTermsTitle0: "Public offer agreement",
      warrantyTermsText0: `Under this Agreement, one party, the Seller, on the one hand, and any person who has accepted the terms of this Public Offer Agreement - the Buyer, on the other hand, hereinafter referred to together as the Parties, have entered into this Public Offer Agreement (hereinafter referred to as the Agreement) addressed to an unlimited number of persons, which is the Seller's official public offer to conclude a contract of sale of the Goods with the Buyers, the photos of which are posted in the relevant section of the Website <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">https://apulse.com.ua/</a>.
Sellers who intend to sell the Goods through the <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> Website and Buyers who purchase the Goods whose images are posted on the relevant pages of <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> accept the terms of this Agreement on the following.`,
      warrantyTermsTitle1: "GENERAL PROVISIONS",
      warrantyTermsText1: `The contractual relationship between the Seller and the Buyer is formalized in the form of a Public Offer Agreement. Clicking the "ORDER" button on the page of the Website <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> in the appropriate section means that the Buyer, regardless of status (individual, legal entity, individual entrepreneur), in accordance with applicable international and Ukrainian legislation, has accepted the terms of the Public Offer Agreement, which are listed below.
1.2. The Public Offer Agreement is public, that is, in accordance with Articles 633, 641 of the Civil Code of Ukraine, its terms are the same for all Buyers regardless of status (individual, legal entity, individual entrepreneur). In case of full agreement with this Agreement, the Buyer accepts the terms and conditions of ordering, payment and delivery of the goods by the Seller, liability for an unfair Order and for failure to comply with the terms of this Agreement.
1.3. This Agreement shall enter into force from the moment you click on the "ORDER" button, by which the Buyer agrees to purchase the Goods available from the Seller and is valid until the Buyer receives the Goods from the Seller and makes full payment to him.
1.4. To regulate the contractual legal relations under the Agreement, the Parties shall choose and, if necessary, apply Ukrainian legislation. If an international treaty, ratified by the Verkhovna Rada of Ukraine, establishes rules other than those established by Ukrainian legislation, the rules of the international treaty shall apply.`,
      warrantyTermsTitle2: "TERMS AND DEFINITIONS",
      warrantyTermsText2: `"Public Offer Agreement" - a public agreement, a sample of which is posted on the <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> Website and the use of which is mandatory for all Sellers, containing the Seller's offer to purchase the Goods, the image of which is posted on the <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> Website, directed to an indefinite number of persons, including Buyers.
"Acceptance" - acceptance by the Buyer of the Seller's offer to purchase the Goods, the image of which is posted on the Website <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a>, by adding it to the virtual basket and sending the Order.
"Goods" - a trade item (product, model, accessory, components and related items, any other trade items), the purchase of which is posted on the Website <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> seller's offer.
"Buyer" - any legally capable individual, legal entity, individual entrepreneur, in accordance with applicable international and Ukrainian law, who has visited the <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> Website and intends to purchase a particular Product.
"Seller" means any capable individual, legal entity, individual entrepreneur, in accordance with the current international and Ukrainian legislation, who owns or distributes the Goods and intends to sell them through the <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> Website.
"Order" - a duly executed and posted on the <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> Website application of the Buyer for the purchase of the Goods addressed to the Seller.
"Legislation" - the norms established by Ukrainian or international legislation for the regulation of contractual legal relations under the Agreement.
"Significant defect of the Goods" - a defect that makes it impossible or unacceptable to use the Goods in accordance with their intended purpose, arose through the fault of the manufacturer (Seller), after its elimination it manifests itself again for reasons beyond the consumer's control.`,
      warrantyTermsTitle3: "CONTRACT SUBJECT",
      warrantyTermsText3: `3.1. The Seller undertakes to sell the Goods on the terms and in the manner specified in this Agreement on the basis of the Order placed by the Buyer on the relevant page of the <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> Website, and the Buyer undertakes to buy the goods and pay for them on the terms and in the manner specified in this Agreement.
3.2. The Seller warrants that the Goods are not pledged, are not subject to a dispute, are not under arrest, and that there are no rights of third parties to them.
3.3 The Seller and the Buyer confirm that the current Agreement is not a fictitious or sham Agreement or an Agreement concluded under the influence of pressure or fraud.
3.4. The Seller confirms that it has all the necessary permits for economic activity, which regulate the scope of legal relations arising and operating in the course of the execution of this Agreement, and also guarantees that it has the right to manufacture and / or sell the goods without any restrictions, in accordance with the requirements of the current legislation of Ukraine, and undertakes to bear responsibility in case of violation of the Buyer's rights in the course of execution of this Agreement and sale of the Goods.`,
      warrantyTermsTitle4: "RIGHTS AND OBLIGATIONS OF THE SELLER",
      warrantyTermsText4: `4.1 The Seller is obliged to:
      <ul>
				<li>fulfill the terms of this Agreement</li>
        <li>fulfill the Buyer's order in case of receipt of payment from the Buyer;</li>
        <li>transfer the Goods to the Buyer in accordance with the selected sample on the corresponding page of the <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> Website, the placed order and the terms of this Agreement;</li>
        <li>check the qualitative and quantitative characteristics of the Goods during its packaging in the warehouse;</li>
			</ul>
4.2. The Seller has the right to:
			<ul>
        <li>unilaterally terminate the provision of services under this Agreement in case of violation by the Buyer of the terms of this Agreement.</li>
			</ul>`,
      warrantyTermsTitle5: "RIGHTS AND OBLIGATIONS OF THE BUYER",
      warrantyTermsText5: `5.1. The Buyer is obliged to:
				<ul>
					<li>timely pay and receive the order on the terms of this Agreement;</li>
      	  <li>familiarize themselves with the information about the goods posted on the Website <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a>;</li>
        	<li>upon receipt of the Goods from the person who delivered them, verify the integrity and completeness of the Goods by inspecting the contents of the package. In case of damage or incomplete completeness of the Goods, record them in the act, which, together with the Buyer, must be signed by the person who delivered it to the Buyer.</li>
				</ul>
5.2. The Buyer has the right to:
				<ul>
        	<li>place an order on the corresponding page of the Website <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a>;</li>
        	<li>require the Seller to fulfill the terms of this Agreement;</li>
				</ul>
				`,
      warrantyTermsTitle6: "ORDERING PROCEDURE",
      warrantyTermsText6: `6.1. The Buyer independently places an order on the corresponding page of the <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> Website by adding the Goods to the virtual basket by clicking the "Add to Cart!" button, or by placing an order by e-mail or by calling the phone number indicated in the contacts section of the <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> Website
6.2. The term for the formation of the Order is up to 2 working days from the date of its execution. If the order is sent on a weekend or holiday, the formation period starts from the first working day after the weekend.`,
      warrantyTermsTitle7: "CONTRACT PRICE AND PAYMENT PROCEDURE",
      warrantyTermsText7: `7.1 The price of each individual Goods is determined by the Seller and is indicated on the corresponding page of the <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> Website. The price of the Agreement is determined by adding the prices of all selected Goods that are in the virtual basket and the delivery price, which is determined depending on the delivery method in accordance with the terms of Section 8 of this Agreement.
7.2. The cost of the Order may vary depending on the price, quantity or range of goods.
7.3. The buyer can pay for the order in the following ways:
				<ol>
					<li>by bank transfer of money to the Seller's current account specified in the invoice, including through Internet banking (the Buyer pays for the order within three days from the date of receipt of the invoice in the amount of 100% prepayment);</li>
					<li>by cash on delivery upon receipt of the Order at the representative office of the delivery service on the territory of Ukraine or on the territory of another country in accordance with the place of ordering the goods;</li>
					<li>in any other way as agreed with the Seller.</li>
				</ol>
				`,
      warrantyTermsTitle8: "TERMS OF DELIVERY OF GOODS",
      warrantyTermsText8: `8.1. The Buyer receives the Goods by delivery or receives them personally. The procedure for payment and receipt is indicated on the corresponding page of the Website <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua.</a>
8.2. When delivering the Goods to other cities of Ukraine or on the territory of another country, performed by other Delivery Services (hereinafter referred to as Carrier Companies), the Buyer fully and unconditionally agrees to the Rules of carriage of goods by these carrier companies.
8.3. The fact of receipt of the Goods and the absence of claims to the quality of the Goods delivered by the Carrier Companies shall be confirmed by the Buyer with his own signature in the consignment note, the declaration of the Carrier Company, or in the expenditure invoice upon receipt of the Goods. For its part, the Seller guarantees the shipment of the Goods to the Carrier Company in the quantity specified and paid for by the Buyer, in a complete set according to the specification of the Goods and in proper (working) condition and quality.
8.4. In case of the Buyer's absence at the delivery address specified by the Buyer in the application or the Buyer's refusal to receive the Goods for unreasonable reasons, upon delivery by the Courier of the carrier company, the Goods shall be returned to the shipping center. Payment for the services of the Carrier Company shall be deducted from the amount paid by the Buyer for the Goods. The balance of the amount shall be returned to the Buyer on the basis of his letter sent to the e-mail: <a href="mailto:info@apulse.com.ua">info@apulse.com.ua</a> indicating the current account to which the funds should be returned. All questions arising in the process of payment and receipt of the Goods can be clarified by the Buyer using the contact information in the Contacts section.`,
      warrantyTermsTitle9: "TERMS OF RETURN OF GOODS",
      warrantyTermsText9: `9.1. In accordance with Art. 9 of the Law of Ukraine "On Consumer Protection", the Buyer has the right to exchange the Goods of good quality within fourteen days, not counting the day of purchase, unless a longer period is announced by the Seller. The Buyer has the right to exchange the Goods, subject to the provisions of the law on the grounds and the list of Goods that are not subject to exchange (return).
9.2. If the Buyer intends to return the Goods, such return shall be made in accordance with the section of the Site "Returns", taking into account the terms and conditions of the carrier or courier in force on the territory of Ukraine or on the territory of another country in accordance with the place of receipt of the Goods.
9.3. In the event of at least one of the listed defects, the Buyer is obliged to record it in a free-form act. The act must be signed by the Buyer and the person who delivered the Goods or the Seller. If possible, defects should be recorded by means of photography or video. Within 1 (one) day, the Buyer is obliged to notify the manager (the Seller's representative responsible for placing the order for the Goods) of the identified defects and agree to replace the Goods, while filling out a complaint form for the return of the Goods on the website <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua.</a>`,
      warrantyTermsTitle10: "LIABILITY OF THE PARTIES AND DISPUTE RESOLUTION",
      warrantyTermsText10: `10.1. The Parties shall be liable for non-fulfillment or improper fulfillment of the terms of this Agreement in the manner prescribed by this Agreement and applicable international and Ukrainian law.
10.2. In the event of disputes related to the Parties' performance of this Agreement, except for disputes on debt collection from the Buyer, the Parties undertake to resolve them through negotiations in compliance with the claim procedure. The term for consideration of the claim is 7 (seven) calendar days from the date of its receipt. For disputes in connection with debt collection from the Buyer, compliance with the claim procedure is not required.
10.3. All disputes, disagreements or claims arising out of or in connection with this Agreement, including those related to its performance, breach, termination or invalidity, shall be resolved in the appropriate court in accordance with international and Ukrainian substantive and procedural law.`,
      warrantyTermsTitle11: "FORCE MAJEURE CIRCUMSTANCES",
      warrantyTermsText11: `11.1. The Parties shall not be liable for failure to fulfill any of their obligations, except for payment obligations, if they prove that such failure was caused by force majeure, i.e. events or circumstances that are really beyond the control of such party, which occurred after the conclusion of this Agreement, are unpredictable and inevitable.
        The force majeure circumstances include, in particular, natural disasters, strikes, fires, floods, explosions, icing, wars (both declared and undeclared), riots, loss of goods, delays of carriers caused by accidents or adverse weather conditions, dangers and accidents at sea, embargoes, disasters, restrictions imposed by governmental authorities (including allocations, priorities, official requirements, quotas and price controls), if these circumstances directly affect the performance of this Agreement.
11.2. The Party for which it became impossible to fulfill its obligations under this Agreement due to the occurrence of force majeure shall immediately inform the other Party in writing of the occurrence of the above circumstances, as well as provide the other Party with confirmation of force majeure within 30 (thirty) calendar days. Such confirmation will be a certificate, certificate or other relevant document issued by an authorized state body located at the place of occurrence of force majeure.
11.3. The time required by the Parties to fulfill their obligations under this Agreement shall be extended for any period during which the performance was postponed due to the above circumstances.
11.4. If, due to force majeure circumstances, the failure to fulfill obligations under this Agreement continues for more than three months, each of the Parties shall have the right to terminate this Agreement unilaterally by notifying the other Party in writing. Notwithstanding the occurrence of force majeure, before termination of this Agreement due to force majeure, the Parties shall make final settlements.`,
      warrantyTermsTitle12: "OTHER TERMS AND CONDITIONS",
      warrantyTermsText12: `12.1. The information provided by the Buyer is confidential. Information about the Buyer is used solely for the purpose of fulfilling his Order (sending a message to the seller about the order of the Goods, sending advertising messages, etc.)
12.2 By own acceptance of the Agreement or registration on the Website <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua.</a> (filling out the registration form), the Buyer voluntarily agrees to the collection and processing of his personal data for the following purposes: the data that becomes known will be used for commercial purposes, including for processing orders for the purchase of goods, receiving information about the order, sending advertising and special offers, information about promotions, sales or any other information about the activities of the <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> Website by telecommunication means (e-mail, mobile communication). For the purposes provided for in this clause, the Buyer has the right to send letters, messages and materials to the postal address, e-mail of the Buyer, as well as send SMS messages, make calls to the phone number specified in the questionnaire.
12.3. The buyer gives the right to process his personal data, including: to place personal data in databases (without additional notice), to carry out lifelong storage of data, their accumulation, updating, changing (as necessary). The Seller undertakes to ensure the protection of data from unauthorized access by third parties, not to distribute or transfer data to any third party (except for the transfer of data to related parties, commercial partners, persons authorized by the Seller to directly process data for the specified purposes, as well as at the mandatory request of the competent state authority).
12.4. In case of unwillingness to receive the newsletter, the Buyer has the right to contact the Seller by writing a statement of refusal to receive advertising materials, sending it to the postal or e-mail address.
12.5. The Seller is not responsible for the content and accuracy of the information provided by the Buyer when placing an order. The Buyer is responsible for the accuracy of the information provided when placing the order.`,
    },
  },
  uk: {
    translation: {
      days: 'днів',
      hours: 'годин',
      minutes: 'хвилин',
      seconds: 'секунд',
      promoFrom: "Акція діє з",
      promoTo: "до",
      left: 'залишилось',
      leftToEnd: 'До кінця акції',
      finished: 'завершена',
      promoFinished: "Завершена",
      day1: 'день',
      day2: 'дні',
      day3: 'днів',
      promoDetails: "Детальні умови акції",
      emptyStateTitle: "Список порожній",
      emptyStateDesc: "На жаль, наразі немає доступних акцій. Будь ласка, спробуйте пізніше або зв'яжіться з нашою службою підтримки для отримання додаткової інформації.",
      productsCatalog: "Каталог товарів",
      catalog: "Каталог",
      news: "Новини",
      warrantyConditions: "Договір публічної оферти",
      aboutUs: "Про нас",
      support: "Підтримка",
      contacts: "Контакти",
      contactUs: "Зв'язатися з нами",
      search: "Пошук",
      openTgBot: "Відкрити телеграм бот",
      info: "Інформація",
      allRightsReserved: "Всі права захищені",
      followUsOnSocial: "Завантажити наш додаток",
      promotions: "Акції",
      promo: "Акція",
      pair: "В парі",
      popularModels: "Популярні моделі",
      sale: "Розпродаж",
      allProducts: "Всі товари",
      newsAndArticles: "Новини та статті",
      sortBy: "Сотрувати за",
      ASC: "Зростанням",
      DESC: "Спаданням",
      promoASC: "Aкцією за зростанням",
      promoDESC: "Aкцією за спаданням",
      select: "Оберіть",
      resetFilters: "Скинути фільтри",
      apply: "Застосувати",
      price: "Ціна",
      available: "В наявності",
      notAvailable: "Немає в наявності",
      alreadyInCart: "Товар вже у кошику",
      aviableNotify: "Отримати сповіщення про наявність товару",
      alreadyNotify:
        "Ми надішлемо сповіщення, як тільки товар буде в наявності",
      productCode: "Код товару",
      buy: "Купити",
      characteristics: "Характеристики",
      description: "Опис",
      reviews: "Відгуки",
      writeReview: "Написати відгук",
      leaveFeedback: "Залишити відгук",
      name: "Ім'я",
      surname: "Призвіще",
      email: "Email",
      mark: "Оцінка",
      message: "Повідомлення",
      showInstruction: "Показати інструкцію",
      minimalCount: "Мінімальна кількість замовлення цього товару",
      noReviews: "Для цього продукту немає відгуків. Ви можете бути першим!",
      goToHomePage: "Перейти на головну сторінку",
      title404: "Сторінку не знайдено",
      message404: "На жаль, ми не можемо знайти сторінку, яку ви шукаєте.",
      queryResult: "Результати за запитом",
      queryNoResult: "Немає результатів за запитом",
      noResultMessage1:
        "На жаль, ми не знайшли жодного товару, що відповідає вашому запиту.",
      noResultMessage2: "Будь ласка, спробуйте змінити параметри пошуку.",
      searchMinLengthError:
        "Пошуковий запит повинен мати довжину не менше 2 символів.",
      login: "Увійти",
      register: "Зареєструватися",
      forgotPass: "Забули пароль",
      pass: "Пароль",
      emailOrPhone: "Email або телефон у форматі +380",
      continue: "Продовжити",
      phone: "Телефон",
      confirmPass: "Підтвердіть пароль",
      country: "Країна",
      region: "Область",
      terms: "Я прочитав і погоджуюсь з ",
      termsWord: "політикою конфіденційності",
      passwordnotSame: "Паролі не однакові",
      passwordLength: "Пароль повинен містити принаймні 6 символів",
      company: "Компанія",
      yourName: "Ваше ім'я",
      yourSurname: "Ваше призвіще",
      enterCompany: "Введіть назву компанії",
      enter: "Введіть",
      error: "Помилка!",
      chooseCountry: "Будь ласка, оберіть країну",
      chooseCity: "Будь ласка, оберіть  місто",
      chooseWarehouse: "Будь ласка, оберіть  відділення Нової Пошти",
      chooseState: "Будь ласка, оберіть  область",
      invalidPhone: "Невірний формат номера телефону",
      confirm: "Підтвердити",
      confirmCodeDescr:
        "Будь ласка, введіть код перевірки, відправлений на ваш номер телефону.",
      confirmCodeDescrEmail:
        "Будь ласка, введіть код перевірки, відправлений на ваш email.",
      confirmEmail: "Будь ласка, підвердіть ваш email.",
      confirmEmailText1:
        "Ми відправили вам лист з посиланням для підтвердження. Будь ласка, перейдіть за посиланням, щоб підтвердити email.",
      confirmEmailText2:
        "Якщо ви не отримали листа, перевірте папку 'Спам' або зверніться до нашої служби підтримки.",
      enterCode: "Ваш код перевірки",
      phoneCode: "Код країни",
      success: "Успіх!",
      notEmailOrPhone:
        "На жаль, ви ввели невірний формат електронної адреси або номера телефону. Будь ласка, перевірте введені дані та спробуйте ще раз.",
      resendCode: "Надіслати код знову",
      maxResendCount: "Максимальна кількість повторних відправлень перевищена.",
      codeAgainSend:
        "Код успішно перевідправлено! Перевірте свій телефон, щоб отримати новий код підтвердження.",
      codeAgainSendEmail:
        "Код успішно перевідправлено! Перевірте свій email, щоб отримати новий код підтвердження.",
      profile: "Профіль",
      favorites: "Обрані товари",
      cart: "Кошик",
      logOut: "Вийти",
      agreeLogout: "Ви впевнені, що хочете вийти з аккаунту?",
      agreeDelete: "Ви впевнені, що хочете видалити всі товари з кошика?",
      agreeClearAllMessages: "Бажаєте зробити всі повідомлення прочитаними?",
      agreeDeleteOne: "Ви впевнені, що хочете видалити товар з кошика?",
      yes: "Так",
      no: "Ні",
      loginOrRegisterTitle: "Увійдіть до аккаунту щоб продовжити",
      loginOrRegister: "Увійти або зареєструватися",
      quantity: "Кількість",
      remove: "Видалити",
      send: "Відправити",
      setArticleRating:
        "Допоможіть нам поліпшити контент, поставивши оцінку цій новині.",
      emptyFavorites: "У вас ще немає товарів у списку улюблених.",
      emptyFavorites2:
        "Знайдіть товар, який вам подобається, та додайте його до улюблених, щоб мати швидкий доступ до нього.",
      successAddedFavorite: "Товар успішно додано до обраних товарів.",
      successAddedWaiting: "Товар успішно додано до списку очікування.",
      successDeleteFavorite: "Товар успішно видалено з обраних товарів.",
      successDeleteWaiting: "Товар успішно видалено зі списку очікування.",
      successAddedToOrder: "Товар успішно додано до кошику.",
      attention: "Увага",
      afterRegisterText:
        "З вами зв'яжуться протягом двох робочих днів для уточнення інформації.",
      ok: "Добре",
      reviewMessage: "Напишіть свій відгук тут...",
      reviewSent: "Дякуємо за ваш відгук!",
      reviewSentInfo: "Після перевірки модератором він буде опублікований.",
      cancel: "Скасувати",
      orders: "Мої замовлення",
      clearCart: "Очистити кошик",
      toOrdering: "Замовити",
      emptyCart1: "У вас ще немає товарів у кошику.",
      emptyCart2:
        "Оберіть товари, які вам сподобалися, та додайте їх до кошика.",
      retailPrice: "Роздрібна ціна",
      wholesalePrice: "Оптова ціна",
      largeWholesalePrice: "Партнер",
      partnerPrice: "Партнерська ціна",
      specialPrice: "Спеціальна ціна",
      total: "Всього",
      promoCode: "Промокод",
      emptyOrders1: "У вас ще немає замовлень.",
      emptyOrders2: "Зробіть перше замовлення, щоб побачити його тут.",
      emptyClosedOrders: "У вас ще немає закритих замовлень.",
      pending: "На розгляді",
      closed: "Закрито",
      piece: "шт.",
      status: "Статус",
      comment: "Коментар",
      yourComment: "Ваш коментар",
      yourMessage: "Ваше повідомлення",
      yourAddress: "Ваша адреса",
      writeUs: "Написати нам",
      discount: "Знижка",
      paymentMethod: "Спосіб оплати",
      fact: "Фактичний",
      nonFact: "Не фактичний",
      workHour: "Режим роботи",
      messagesIsRead: "Всі повідомлення прочитано!",
      workHourVal: "Пн-Сб: 9:00-21:00, Нд: вихідний.",
      saleDep: "Відділ продажів",
      techDep: "Технічна підтримка",
      orderType: "Спосіб доставки",
      delivery: "Доставка кур'єром Нової Пошти",
      pickup: "Самовивіз (м. Харків)",
      deliveryToWarehouse: "Доставка до відділення Нової Пошти",
      city: "Місто",
      state: "Область",
      warehouse: "Відділення",
      address: "Адреса",
      processOrder: "Оформити замовлення",
      factPay: "Оплата за фактом (без відстрочки платежу). Дає знижку - 5%",
      chooseOrderType: "Оберіть спосіб доставки",
      warehouseNP: "До відділення Нової Пошти",
      courierNP: "Кур'єром Нової Пошти",
      pickupAddress:
        'м. Харків, Ващенківський провулок 28Б, 1 поверх, магазин "Автозвук"',
      address2: "м. Харків, провулок Ващенківський, 28 «Б»",
      pickupText1: "Після оформлення замовлення",
      pickupStrongText: "відмічені товари бронюються за Вами на термін 3 дні.",
      pickupText2:
        "По закінченню терміну, якщо товар не реалізований, бронь знімається і товар може бути проданий іншому покупцеві.",
      totalSum: "Загальна сума",
      addedToOrder: "У кошику",
      currentOrders: "Поточні замовлення",
      historyOrders: "Історія замовлень",
      thanksForOrder: "Дякуємо за замовлення!",
      order: "Замовлення",
      orderInfo:
        "З вами зв'яжеться наш менеджер для уточнення деталей замовлення.",
      uah: "грн",
      грн: "грн",
      ye: "y.e.",
      change: "Змінити",
      noChange: "Жодні дані не змінено",
      notifications: "Повідомлення",
      emptyNotifications: "У вас ще немає повідомлень.",
      emptyNotifyProducts: "Товари ще не були додані до списку для сповіщень.",
      developed: "Розроблено",
      applied: "застосований",
      notifyTitle: "Список очікування",
      downloadPriceList: "Завантажити прайс-лист",
      downloadShortPriceList: "Завантажити скорочений прайс-лист",
      downloadXML: "Завантажити XML файл",
      guiideByChoice: "Гід по вибору",
      successConfirmEmail: "Ви успішно підтвердили свою електронну адресу!",
      failConfirmEmail: "На жаль, не вдалося підтвердити електрону адресу.",
      storage: "Cклад",
      availability: "Наявність",
      allWarehouses: "На всіх складах",
      toOrder: "Під замовлення 2-3 дні",
      noResultByFilter:
        "На жаль, ми не знайшли відповідні товари за обраним фільтром.",
      errorAuth: "Щось пішло не так. Зверніться в службу підтримки.",

      this_phone_number_is_already_in_use:
        "Цей номер телефону вже використовується",
      this_email_is_already_in_use:
        "Ця адреса електронної пошти вже використовується",
      this_email_already_in_use:
        "Ця адреса електронної пошти вже використовується",
      today_max_phone_code_amount_expired:
        "Перевищено ліміт кількості запитів на коди на сьогодні",
      code_is_expite_or_wrong: "Код прострочений або введений неправильно",
      login_or_password_wrong: "Невірний логін або пароль",
      user_not_found: "Користувача не знайдено",
      please_confirm_your_phone: "Будь ласка, підтвердьте свій номер телефону.",
      promo_code_not_found:
        "Промокод не знайдено. Будь ласка, перевірте правильність введеного коду та спробуйте ще раз.",
      promo_code_expired:
        "Упс! Промокод, який ви намагаєтеся використати, вже закінчився.",
      promo_code_usage_amount_exhausted:
        "На жаль, кількість використань даного промокоду вичерпано. Будь ласка, спробуйте використати інший промокод.",
      you_already_use_this_promo_code: "Ви вже використали цей промокод.",
      promo_code_start_date_has_not_yet_arrived:
        "Дата початку дії промокоду ще не настала.",
      warrantyTermsTitle0: "Договір публічної оферти",
      warrantyTermsText0: `За цим Договором одна сторона Продавець з одного боку, і будь-яка особа, яка прийняла умови цього Договору публічної оферти - Покупець, з іншого боку, надалі разом іменовані - Сторони, уклали цей Договір публічної оферти (далі - Договір), адресований необмеженому колу осіб, що є офіційною публічною пропозицією Продавця укласти з Покупцями договір купівлі-продажу Товарів, фотографії яких розміщені у відповідному розділі Веб-сайту <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">https://apulse.com.ua/</a>.
Продавці, що мають намір здійснювати продаж Товарів за допомогою Веб-сайту <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> і Покупці при придбанні Товарів, зображення яких розміщені на відповідних сторінках <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a>, приймають умови цього Договору про наступне.`,
      warrantyTermsTitle1: "ЗАГАЛЬНІ ПОЛОЖЕННЯ",
      warrantyTermsText1: `Договірні відносини між Продавцем і Покупцем оформляються у вигляді Договору публічної оферти. Натискання на сторінці Веб-сайту <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> у відповідному розділі кнопки «ЗАМОВИТИ» означає, що Покупець, незалежно від статусу (фізична особа, юридична особа, фізична особа-підприємець), згідно з чинним міжнародним та українським законодавством , взяв до виконання умови Договору публічної оферти, які вказані нижче.
1.2. Договір публічної оферти є публічним, тобто відповідно до статей 633, 641 Цивільного кодексу України його умови однакові для всіх Покупців незалежно від статусу (фізична особа, юридична особа, фізична особа-підприємець). При повній згоді з цим Договором Покупець приймає умови і порядок оформлення замовлення, оплати та доставки товару Продавцем, відповідальності за недобросовісне Замовлення і за невиконання умов цього Договору.
1.3. Цей Договір набуває чинності з моменту натискання на кнопку «ЗАМОВИТИ», яким Покупець дає згоду здійснити покупку наявного у Продавця Товару і діє до моменту отримання Покупцем Товару від Продавця і повного розрахунку з ним.
1.4. Для регулювання договірних правовідносин за Договором Сторони вибирають і, в разі необхідності, застосовують українське законодавство. Якщо міжнародним договором, згода на обов'язковість якого надана Верховною Радою України, встановлено інші правила, ніж ті, які встановлені українським законодавством, то застосовуються правила міжнародного договору.`,
      warrantyTermsTitle2: "ТЕРМІНИ ТА ВИЗНАЧЕННЯ",
      warrantyTermsText2: `«Договір публічної оферти» - публічний договір, зразок якого розміщено на Веб- <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> и застосування якого є обов'язковим для всіх Продавців, що містить пропозицію Продавця про придбання Товару, зображення якого розміщено на Веб-сайті <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a>, спрямоване невизначеному колу осіб, у тому числі Покупцям.
«Акцепт» - прийняття Покупцем пропозиції продавця про придбання Товару, зображення якого розміщено на Веб-сайті <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a>, шляхом додавання його в віртуальну корзину і відправки Замовлення.
«Товар» - предмет торгівлі (виріб, модель, аксесуар, комплектуючі та супутні предмети, будь-які інші предмети торгівлі), про придбання якого на Веб-сайті <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> розміщено пропозицію продавця.
«Покупець» - будь-яка дієздатна фізична, юридична особа, фізична особа-підприємець, згідно з чинним міжнародним та українським законодавством, які відвідали Веб-сайт <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> і мають намір придбати той чи інший Товар.
«Продавець» - будь-яка дієздатна фізична, юридична особа, фізична особа-підприємець, згідно з чинним міжнародним та українським законодавством, які є власниками або розповсюджувачами Товару і за допомогою Веб-сайту <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> мають намір його продати.
«Замовлення» - належним чином оформлена і розміщена на Веб-сайті <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> заявка Покупця на купівлю Товарів, адресующаяся Продавцю.
«Законодавство» - встановлені українським або міжнародним законодавством норми для регулювання договірних правовідносин за Договором.
«Істотний недолік Товару» - недолік, який робить неможливим чи недопустимим використання Товару відповідно до його цільового призначення, виник з вини виробника (Продавця), після його усунення проявляється знову з незалежних від споживача причин.`,
      warrantyTermsTitle3: "ПРЕДМЕТ ДОГОВОРУ",
      warrantyTermsText3: `3.1. Продавець зобов'язується на умовах та в порядку, визначених цим Договором продати Товар на основі Замовлення, оформленого Покупцем на відповідній сторінці Веб-сайту <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a>, а Покупець зобов'язується на умовах та в порядку, визначених цим Договором, купити товар і сплатити за нього гроші.
3.2. Продавець гарантує, що Товар не переданий в заставу, не є предметом спору, не перебуває під арештом, а також на нього відсутні будь-які права третіх осіб.
3.3. Продавець і Покупець підтверджують, що чинний Договір не є фіктивною або удаваним Договором або Договором, укладеним під впливом тиску або обману.
3.4. Продавець підтверджує, що має всі необхідні дозволи на здійснення господарської діяльності, які регулюють сферу правовідносин, що виникають і діють в процесі виконання цього Договору, а також гарантує, що має право на виробництво та / або реалізацію товару без будь-яких обмежень, відповідно до вимогами чинного законодавства України, і зобов'язується нести відповідальність в разі порушення прав Покупця в процесі виконання цього Договору та реалізації Товару.`,
      warrantyTermsTitle4: "ПРАВА ТА ОБОВ'ЯЗКИ ПРОДАВЦЯ",
      warrantyTermsText4: `4.1. Продавець зобов'язаний:
				<ul>
					<li>виконувати умови цього Договору</li>
					<li>виконувати замовлення Покупця в разі надходження оплати від Покупця;</li>
					<li>передати Покупцю Товар відповідно до обраного зразком на відповідній сторінці Веб-сайта <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a>, оформленим замовленням і умов цього Договору;</li>
					<li>перевірити якісні та кількісні характеристики Товару під час його упаковки на складі;</li>
				</ul>
4.2. Продавець має право:
				<ul>
					<li>в односторонньому порядку припинити надання послуг за цим Договором у разі порушення Покупцем умов цього Договору.</li>
				</ul>`,
      warrantyTermsTitle5: "ПРАВА ТА ОБОВ'ЯЗКИ ПОКУПЦЯ",
      warrantyTermsText5: `5.1. Покупець зобов'язаний:
        <ul>
					<li>своєчасно оплатити та отримати замовлення на умовах цього Договору;</li>
        	<li>ознайомитися з інформацією про товар, розміщеної на Веб-сайті <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a>;</li>
        	<li>при отриманні Товару у особи, яка вчинила його доставку, переконатися в цілісності і комплектності Товару шляхом огляду вмісту упаковки. У разі пошкодження або неповної комплектації Товару - зафіксувати їх в акті, який разом з Покупцем має підписати особа, яка здійснила його доставку Покупцеві.</li>
				</ul>
5.2. Покупець має право:
			<ul>
				<li>оформити замовлення на відповідній сторінці Веб-сайту <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a>;</li>
        <li>вимагати від продавця виконання умов цього Договору;</li>
			</ul>`,
      warrantyTermsTitle6: "ПОРЯДОК ОФОРМЛЕННЯ ЗАМОВЛЕННЯ",
      warrantyTermsText6: `6.1. Покупець самостійно оформляє замовлення на відповідній сторінці Веб-сайту <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> шляхом додавання Товарів в віртуальну корзину за допомогою натискання кнопки «В кошик!», Або зробивши замовлення по електронній пошті, або за номером телефону, зазначеному в розділі контактів Веб-сайту <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a>
6.2. Термін формування Замовлення до 2 робочих днів з моменту його оформлення. У разі, якщо замовлення відправлений у вихідний або святковий день, термін формування починається з першого після вихідного робочого дня.`,
      warrantyTermsTitle7: "ЦІНА ДОГОВОРУ І ПОРЯДОК ОПЛАТИ",
      warrantyTermsText7: `7.1. Ціна кожного окремого Товару визначається Продавцем і вказується на відповідній сторінці Веб-сайту <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua.</a> Ціна Договору визначається шляхом додавання цін всіх обраних Товарів, які перебувають у віртуальній корзині і ціни доставки, яка визначається в залежності від способу доставки відповідно до умов розділу 8 цього Договору.
7.2. Вартість Замовлення може змінюватися в залежності від ціни, кількості або номенклатури товару.
7.3. Покупець може оплатити замовлення наступними способами:
        <ol>
				<li>за допомогою банківського переказу грошей на поточний рахунок Продавця, вказаний в рахунку, в т.ч. за допомогою Інтернет-банкінгу (Покупець оплачує замовлення протягом трьох днів з дати отримання рахунку в розмірі 100% передоплати);</li>
        <li>післяплатою при отриманні Замовлення в представництві служби доставки на території України або на території іншої країни відповідно до місця здійснення замовлення товару;</li>
        <li>будь-яким іншим способом за домовленістю з Продавцем.</li>
				</ol>`,
      warrantyTermsTitle8: "УМОВИ ДОСТАВКИ ТОВАРУ",
      warrantyTermsText8: `8.1. Покупець отримує Товар за допомогою доставки, або отримує його особисто. Порядок оплати і отримання зазначений на відповідній сторінці Веб-сайту <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua.</a>
8.2. При доставці Товарів в інші міста України або на території іншої країни, виконуваної іншими Службами доставки (далі Компаніями-перевізниками) Покупець в повному обсязі і беззастережно погоджується з Правилами перевезення вантажів цими компаніями-перевізниками.
8.3. Факт отримання Товару та відсутність претензій до якості Товару, що доставляється Компаніями-перевізниками, Покупець підтверджує власним підписом в товаро-транспортної накладної, декларації Компанії-перевізника, або у видатковій накладній при отриманні Товару. Зі свого боку, Продавець гарантує відвантаження Товару Компанії-перевізнику в кількості, зазначеній і сплаченому Покупцем, в комплектності згідно специфікації цього Товару та в належному (робочому) стані і якості.
8.4. У разі відсутності Покупця за адресою доставки, зазначеної Покупцем в заявці або відмови Покупця від отримання Товару по необґрунтованих причин, при доставці Кур'єром компанії-перевізника, Товар повертається в торговий центр відвантаження. Оплата за послуги Компанії-перевізника віднімається від суми, перерахованої Покупцем за Товар. Залишок суми повертається Покупцю на підставі його листа, відправленого на e-mail: <a href="mailto:info@apulse.com.ua">info@apulse.com.ua</a> із зазначенням розрахункового рахунку, на який повинні бути повернуті кошти. Всі питання, що виникли в процесі оплати та отримання Товару, Покупець може з'ясувати по контактними даними в розділі Контакти.`,
      warrantyTermsTitle9: "УМОВИ ПОВЕРНЕННЯ ТОВАРУ",
      warrantyTermsText9: `9.1. Відповідно до ст. 9 Закону України «Про захист прав споживачів», Покупець має право на обмін Товару належної якості протягом чотирнадцяти днів, не рахуючи дня покупки, якщо триваліший строк не оголошений Продавцем. Покупець має право на обмін Товару з урахуванням положень законодавства про підстави і переліку Товарів, які не підлягають обміну (поверненню).
9.2. Якщо Покупець має намір повернути Товар, таке повернення здійснюється відповідно до розділу Сайту «Повернення» з урахуванням правил і умов перевізника або кур'єра, які діють на території України або на території іншої країни відповідно до місця скоєння отримання Товару.
9.3. У разі наявності хоча б одного з перерахованих недоліків, Покупець зобов'язаний зафіксувати його в складеному акті довільної форми. Акт повинен бути підписаний Покупцем і особою, яка здійснила доставку Товару або Продавцем. При можливості недоліки повинні бути зафіксовані засобами фото- або відеозйомки. Протягом 1 (одного) дня Покупець зобов'язаний повідомити менеджера (представника Продавця відповідального за оформлення замовлення на Товар) про виявлені недоліки і домовитися про заміну Товару, заповнивши при цьому рекламаційну форму на повернення Товару на сайті <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua.</a>`,
      warrantyTermsTitle10: "ВІДПОВІДАЛЬНІСТЬ СТОРІН І ВИРІШЕННЯ СПОРІВ",
      warrantyTermsText10: `10.1. Сторони несуть відповідальність за невиконання або неналежне виконання умов цього Договору в порядку, передбаченому цим Договором та чинним міжнародним та українським законодавством.
10.2. У разі виникнення суперечок, пов'язаних з виконанням Сторонами цього Договору, за винятком спорів про стягнення заборгованості з покупця, Сторони зобов'язуються вирішувати їх шляхом переговорів з дотриманням претензійного порядку. Термін розгляду претензії - 7 (сім) календарних днів з дати її отримання. По спорах у зв'язку зі стягненням заборгованості з Покупця дотримання претензійного порядку не потрібно.
10.3. Усі спори, розбіжності або вимоги, що виникають з цього Договору або в зв'язку з ним, у тому числі що стосуються його виконання, порушення, припинення або недійсності, підлягають вирішенню у відповідному суді відповідно до міжнародного та українського матеріального і процесуального права.`,
      warrantyTermsTitle11: "ФОРС-МАЖОРНІ ОБСТАВИНИ",
      warrantyTermsText11: `11.1. Сторони не несуть відповідальності за невиконання будь-якого зі своїх зобов'язань, за винятком зобов'язань по оплаті, якщо доведуть, що таке невиконання було викликано форс-мажорними обставинами, тобто подіями або обставинами, які дійсно перебувають поза контролем такої сторони, які настали після укладення цього Договору, носять непередбачуваний і невідворотний характер.
До форс-мажорних обставин належать, зокрема, природні катаклізми, страйки, пожежі, повені, вибухи, обмерзання, війни (як оголошені, так і неоголошені), заколоти, загибель товару, затримки перевізників, викликані аваріями або несприятливими погодними умовами, небезпеки і випадковості на морі, ембарго, катастрофи, обмеження, що накладаються державними органами (включаючи розподілу, пріоритети, офіційні вимоги, квоти і ціновий контроль), якщо ці обставини безпосередньо вплинули на виконання цього Договору.
11.2. Сторона, для якої стало неможливим виконання зобов'язань за цим Договором через настання форс-мажорних обставин, повинна негайно інформувати іншу Сторону в письмовому вигляді про виникнення вищевказаних обставин, а також протягом 30 (тридцяти) календарних днів надати іншій Стороні підтвердження форс-мажорних обставин. Таким підтвердженням буде довідка, сертифікат або інший відповідний документ, виданий уповноваженим державним органом, розташованим за місцем виникнення форс-мажорних обставин.
11.3. Час, який потрібен Сторонам для виконання своїх зобов'язань за цим Договором, буде продовжено на будь-який термін, протягом якого було відкладено виконання через перерахованих обставин.
11.4. Якщо через дії обставин непереборної сили невиконання зобов'язань за цим Договором продовжується більше трьох місяців, кожна зі Сторін має право розірвати цей Договір в односторонньому порядку, письмово повідомивши про це іншу сторону. Незважаючи на наступ форс-мажору, перед припиненням цього Договору внаслідок форс-мажорних обставин Сторони здійснюють остаточні взаєморозрахунки.`,
      warrantyTermsTitle12: "ІНШІ УМОВИ",
      warrantyTermsText12: `12.1. Інформація, яку надає Покупцем є конфіденційною. Інформація про Покупця використовується виключно в цілях виконання його Замовлення (відправки повідомлення продавцю про замовлення Товару, відправки рекламних повідомлень і інш.).
12.2. Власним акцептування Договору або реєстрацією на Веб-сайті <a target="_blank" rel="noreferrer" href="https://apulse.com.ua/">apulse.com.ua</a> (заповнення реєстраційної анкети) Покупець добровільно дає згоду на збір і обробку своїх персональних даних з подальшою метою: дані, які стають відомі, будуть використовуватися в комерційних цілях , в тому числі для обробки замовлень на придбання товарів, отримання інформації про замовлення, розсилки телекомунікаційними засобами зв'язку (електронною поштою, мобільним зв'язком) рекламних та спеціальних пропозицій, інформації про акції, роз Гриша або будь-який інший інформації про діяльність Веб-сайта <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua.</a> Для цілей, передбачених цим пунктом, Покупцеві мають право направлятися листи, повідомлення та матеріали на поштову адресу, e-mail Покупця, а також відправлятися sms-повідомлення, здійснюватися дзвінки на вказаний в анкеті телефонний номер.
12.3. Покупець дає право здійснювати обробку його персональних даних, в тому числі: поміщати персональні дані в бази даних (без додаткового повідомлення про це), здійснювати довічне зберігання даних, їх накопичення, оновлення, зміна (у міру необхідності). Продавець зобов'язується забезпечити захист даних від несанкціонованого доступу третіх осіб, не поширювати і не передавати дані будь-якої третьої сторони (крім передачі даних пов'язаним особам, комерційним партнерам, особам, уповноваженим Продавцем на здійснення безпосередньої обробки даних для зазначених цілей, а також на обов'язковий запит компетентного державного органу).
12.4. У разі небажання отримувати розсилку, Покупець має право звернутися до Продавця, написавши заяву про відмову від отримання рекламних матеріалів, направивши його на поштову або електронну адресу.
12.5. Продавець не несе відповідальності за зміст і достовірність інформації, наданої Покупцем при оформленні замовлення. Покупець несе відповідальність за достовірність інформації, зазначеної при оформленні замовлення інформації.`,
    },
  },
};

i18n.init({
  resources,
  lng: localStorage.getItem(LOCAL_STORAGE.language) || DEFAULT_LANGUAGE,
  fallbackLng: DEFAULT_LANGUAGE,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
