import { IRootState } from "../../store";

export const selectProducts = (state: IRootState) => state.products.products;
export const selectProductsLoading = (state: IRootState) =>
  state.products.isLoading;
export const selectHiddenProductsLoading = (state: IRootState) =>
  state.products.isHiddenLoading;
export const selectProductsFinish = (state: IRootState) =>
  state.products.productsFinish;
export const selectProductsPrices = (state: IRootState) =>
  state.products.productsPrices;

export const selectFilters = (state: IRootState) => state.products.filters;

export const selectCurrentSettings = (state: IRootState) =>
  state.products.currentSettings;

export const selectSingleProduct = (state: IRootState) =>
  state.products.singleProduct;
export const selectSingleProductLoading = (state: IRootState) =>
  state.products.isProductLoading;

export const selectProductCharacteristics = (state: IRootState) =>
  state.products.productCharacteristics;
export const selectIsRadioBot = (state: IRootState) =>
  state.products.isRadioBot;
export const selectProductCharacteristicsLoading = (state: IRootState) =>
  state.products.isProductCharacteristicsLoading;

export const selectProductDescription = (state: IRootState) =>
  state.products.productDescription;
export const selectProductDescriptionLoading = (state: IRootState) =>
  state.products.isProductDescriptionLoading;

export const selectSaleProducts = (state: IRootState) =>
  state.products.saleProducts;
export const selectSaleProductsLoading = (state: IRootState) =>
  state.products.isSaleProductsLoading;
export const selectHiddenSaleProductsLoading = (state: IRootState) =>
  state.products.isHiddenSaleProductsLoading;

export const selectCategoryFilterType = (state: IRootState) =>
  state.products.categoryFilterType;
