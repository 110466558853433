import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { findCatalogById } from "../../utils/findCatalogById";
import { getCategoryProducts } from "../../store/products/useCases/getCategoryProducts/action";
import { saveCategoryFilterType, saveCurrentSettings } from "../../store/products/repository/actions";
import { getFilters } from "../../store/products/useCases/getFilters/action";
import { selectCurrentLang } from "../../store/language/repository/selectors";
import { selectCurrentSettings, selectProductsFinish, selectProductsLoading } from "../../store/products/repository/selectors";
import { selectCatalog } from "../../store/catalog/repository/selectors";
import { selectisLoggedIn } from "../../store/user/repository/selectors";
import { selectPrevPathname } from "../../store/app/repository/selectors";
import { ROUTES } from "../../constants";
import Products from "../../ui-kit/products/products";
import { TelegramButton } from "../../ui-kit/telegramButton/telegramButton";

const ProductListPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const productsLoading = useSelector(selectProductsLoading);
  const productsFinish = useSelector(selectProductsFinish);

  const currentSettings = useSelector(selectCurrentSettings);
  const prevPathname = useSelector(selectPrevPathname);
  const catalog = useSelector(selectCatalog);
  const language = useSelector(selectCurrentLang);
  const isLoggedIn = useSelector(selectisLoggedIn);
  const item = findCatalogById(parseInt(id || "0"), catalog);

  const [isShownTgButton, setShownTgButton] = useState(false);
  
  useEffect(() => {
    if (currentSettings?.id !== id) {
      dispatch(saveCurrentSettings({ id }));
    }
  }, [id]);

  useEffect(() => {
    if (item && item.sortFilterType) {
      dispatch(saveCategoryFilterType(item.sortFilterType));
    }
  }, [id, catalog]);

  useEffect(() => {
    if (prevPathname && prevPathname.includes(ROUTES.SearchResultPage)) {
      dispatch(saveCurrentSettings({ id }));
      dispatch(getCategoryProducts({ id, isSavePrice: true }));
    } else {
      dispatch(
        getCategoryProducts({ /* ...currentSettings, */ id, isSavePrice: true })
      );
    }
  }, [language, isLoggedIn, id]);

  useEffect(() => {
    if (!productsLoading && productsFinish) {
      dispatch(getFilters(id, currentSettings?.filter));
    }
  }, [productsLoading, productsFinish]);

  useEffect(() => {
    if (item?.isPartOfTgRadioBot) {
      setShownTgButton(true);
    } else {
      setShownTgButton(false);
    }
    return () => setShownTgButton(false);
  }, [item]); 

  return (
    <>
      <Products title={item?.name || ""} description={item?.description || ""} />
      {isShownTgButton && <TelegramButton />}
    </>
  );
};

export default ProductListPage;
