import { all, fork } from "redux-saga/effects";
import { getCatalogWatcher } from "./catalog/useCases/getCatalog/worker";
import { getProductsWatcher } from "./products/useCases/getCategoryProducts/worker";
import { getFiltersWatcher } from "./products/useCases/getFilters/worker";
import { getLanguagesWatcher } from "./language/useCases/worker";
import { getSingleProductWatcher } from "./products/useCases/getSingleProduct/worker";
import { getProductCharacteristicsWatcher } from "./products/useCases/getCharacteristics/worker";
import { getProductDescriptionWatcher } from "./products/useCases/getDescription/worker";
import { getNewsWatcher } from "./news/useCases/getNews/worker";
import { getSingleNewsWatcher } from "./news/useCases/getSingleNews/worker";
import { getGroupProductsWatcher } from "./groupProducts/useCases/getGroupList/worker";
import { getSaleProductsWatcher } from "./products/useCases/getSaleProducts/worker";
import { getPromoProductsWatcher } from "./promo/useCases/getPromoProducts/worker";
import { getFavoritesProductsWatcher } from "./favorites/useCases/getFavorites/worker";
import { getProductReviewsWatcher } from "./reviews/useCases/getProductReviews/worker";
import { getBannersWatcher } from "./banners/useCases/getBanners/worker";
import { getCartWatcher } from "./orders/useCases/getCart/worker";
import { getUserWatcher } from "./user/useCases/getUser/worker";
import { getNotificationsWatcher } from "./notifications/useCases/getNotifications/worker";
import { getWaitingListWatcher } from "./waitingList/useCases/getWaitingList/worker";
import { getPriceListWatcher } from "./excel/useCases/getPriceList/worker";
import { getShortPriceListWatcher } from "./excel/useCases/getShortPriceList/worker";
import { getProductsFileWatcher } from "./excel copy/useCases/productsFile/worker";

import { loginWatcher } from "./user/useCases/login/worker";
import { restorePassWatcher } from "./user/useCases/restorePass/worker";
import { registerWatcher } from "./user/useCases/register/worker";
import { confirmWatcher } from "./user/useCases/confirm/worker";
import { confirmPasswordWatcher } from "./user/useCases/confirmPassword/worker";
import { addRatingWatcher } from "./news/useCases/addRating/worker";
import { updateUserWatcher } from "./user/useCases/updateUser/worker";
import { addToFavoriteWatcher } from "./favorites/useCases/addFavorite/worker";
import { deleteFromFavoriteWatcher } from "./favorites/useCases/deleteFromFavorite/worker";
import { addProductReviewWatcher } from "./reviews/useCases/addProductReview/worker";
import { getOrdersWatcher } from "./orders/useCases/getOrders/worker";
import { addProductToOrderWatcher } from "./orders/useCases/addProductToOrder/worker";
import { createOrderWatcher } from "./orders/useCases/createOrder/worker";
import { updateCartProductWatcher } from "./orders/useCases/updateCartProduct/worker";
import { deleteCartProductWatcher } from "./orders/useCases/deleteCartProduct/worker";
import { deleteAllCartWatcher } from "./orders/useCases/deleteAllCart/worker";
import { fillCartWatcher } from "./orders/useCases/fillCart/worker";
import { activatePromoCodeWatcher } from "./promoCode/useCases/activate/worker";
import { setNotificationsReadWatcher } from "./notifications/useCases/setNotificationsRead/worker";
import { addToWaitingWatcher } from "./waitingList/useCases/addWaitingProduct/worker";
import { deleteFromWaitingWatcher } from "./waitingList/useCases/deleteWaitingProduct/worker";
import { createFeedbackWatcher } from "./feedback/useCases/createFeedback/worker";
import { setNotificationsReadAllWatcher } from "./notifications/useCases/setNotificationsReadAll/worker";
import { getSinglePromoWatcher } from "./promo/useCases/getSinglePromo/worker";
import { getPromoListWatcher } from "./promo/useCases/getPromoList/worker";
import { getTelegramBotLinkWatcher } from "./user/useCases/getTelegramBotLink/worker";

export function* rootSaga() {
  yield all([
    fork(getCatalogWatcher),
    fork(getLanguagesWatcher),
    fork(getProductsWatcher),
    fork(getFiltersWatcher),
    fork(getSingleProductWatcher),
    fork(getProductCharacteristicsWatcher),
    fork(getProductDescriptionWatcher),
    fork(getGroupProductsWatcher),
    fork(getSaleProductsWatcher),
    fork(getPromoProductsWatcher),
    fork(getNewsWatcher),
    fork(getSingleNewsWatcher),
    fork(getFavoritesProductsWatcher),
    fork(getProductReviewsWatcher),
    fork(getOrdersWatcher),
    fork(getCartWatcher),
    fork(getUserWatcher),
    fork(getBannersWatcher),
    fork(getNotificationsWatcher),
    fork(setNotificationsReadAllWatcher),
    fork(getWaitingListWatcher),
    fork(getPriceListWatcher),
    fork(getShortPriceListWatcher),
    fork(getProductsFileWatcher),
    fork(getSinglePromoWatcher),
    fork(getPromoListWatcher),

    fork(loginWatcher),
    fork(getTelegramBotLinkWatcher),
    fork(restorePassWatcher),
    fork(registerWatcher),
    fork(confirmWatcher),
    fork(confirmPasswordWatcher),
    fork(addRatingWatcher),
    fork(updateUserWatcher),
    fork(addToFavoriteWatcher),
    fork(deleteFromFavoriteWatcher),
    fork(addProductReviewWatcher),
    fork(addProductToOrderWatcher),
    fork(createOrderWatcher),
    fork(updateCartProductWatcher),
    fork(deleteCartProductWatcher),
    fork(deleteAllCartWatcher),
    fork(fillCartWatcher),
    fork(activatePromoCodeWatcher),
    fork(setNotificationsReadWatcher),
    fork(addToWaitingWatcher),
    fork(deleteFromWaitingWatcher),
    fork(createFeedbackWatcher),
  ]);
}
