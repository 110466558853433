import { FC } from "react";
import { FaTelegram } from "react-icons/fa";
import { ROUTES } from "../../constants";
import './telegramButton.scss';
import { Tooltip } from "react-tooltip";
import i18n from "../../locales";
import { useDispatch } from "react-redux";
import { getTelegramBotLink } from "../../store/user/useCases/getTelegramBotLink/actions";

export const TelegramButton: FC = () => {
    const dispatch = useDispatch();
    
    const handleButton = () => {
        dispatch(getTelegramBotLink());
    };

    const title = i18n.t("openTgBot");

    return (
        <>
        <Tooltip id="telegram_tooltip" />
        <button
            className="telegram-button"
            onClick={handleButton}
            data-tooltip-id="telegram_tooltip"
            data-tooltip-content={title}
        >
            <FaTelegram size={30} />
        </button>
    </>
    )
};