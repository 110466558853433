import { IResponseProduct } from "./IResponseProduct";
import { IProduct } from "./IProduct";
import { ICharacteristic } from "./ICharacteristic";
import { IProductDescription } from "./IProductDescription";
import { IProductSettings } from "./IProductSettings";
import { IPrices } from "./IPrices";
import { ISaleProducts } from "./ISaleProducts";
import { IFilter } from "./IFilter";

interface IInitialState {
  products: IResponseProduct;
  productsPrices: IPrices;
  filters: IFilter[];
  filtersLoading: boolean;
  isLoading: boolean;
  isHiddenLoading: boolean;
  singleProduct: IProduct | null;
  isProductLoading: boolean;
  productsFinish: boolean;
  productCharacteristics: ICharacteristic[];
  isProductCharacteristicsLoading: boolean;
  productDescription: IProductDescription[];
  isProductDescriptionLoading: boolean;
  currentSettings: IProductSettings | null;
  saleProducts: ISaleProducts;
  isSaleProductsLoading: boolean;
  isHiddenSaleProductsLoading: boolean;
  categoryFilterType: string | null;
  isRadioBot: boolean;
}

const initialState: IInitialState = {
  products: {
    count: 0,
    data: [],
    filters: [],
    prices: {
      maxPrice: 0,
      minPrice: 0,
    },
  },
  productsPrices: {
    maxPrice: 0,
    minPrice: 0,
  },
  filters: [],
  filtersLoading: false,
  isLoading: false,
  isHiddenLoading: false,
  productsFinish: false,
  currentSettings: null,
  singleProduct: null,
  isProductLoading: false,
  productCharacteristics: [],
  isProductCharacteristicsLoading: false,
  productDescription: [],
  isProductDescriptionLoading: false,
  saleProducts: {
    categories: [],
    products: [],
    count: 0,
  },
  isSaleProductsLoading: false,
  isHiddenSaleProductsLoading: false,
  categoryFilterType: null,
  isRadioBot: false
};

export function productsReducer(
  state: IInitialState = initialState,
  action: any
): IInitialState {
  switch (action.type) {
    case "SAVE_PRODUCTS":
      return { ...state, products: action.payload };
    case "SET_PRODUCTS_LOADING":
      return { ...state, isLoading: action.payload };
    case "SET_HIDDEN_PRODUCTS_LOADING":
      return { ...state, isHiddenLoading: action.payload };
    case "SET_PRODUCTS_FINISH":
      return { ...state, productsFinish: action.payload };

    case "SAVE_PRODUCTS_PRICES":
      return { ...state, productsPrices: action.payload };

    case "SAVE_FILTERS":
      return { ...state, filters: action.payload };

    case "SAVE_CURRENT_SETTINGS":
      return { ...state, currentSettings: action.payload };

    case "SAVE_SINGLE_PRODUCT":
      return { ...state, singleProduct: action.payload };
    case "SAVE_SINGLE_PRODUCT_LOADING":
      return { ...state, isProductLoading: action.payload };

    case "SAVE_PRODUCT_CHARACTERISTICS":
      return { ...state, productCharacteristics: action.payload };
    case "SAVE_RADIO_BOT":
      return { ...state, isRadioBot: action.payload };
    case "SAVE_PRODUCT_CHARACTERISTICS_LOADING":
      return { ...state, isProductCharacteristicsLoading: action.payload };

    case "SAVE_PRODUCT_DESCRIPTION":
      return { ...state, productDescription: action.payload };
    case "SAVE_PRODUCT_DESCRIPTION_LOADING":
      return { ...state, isProductDescriptionLoading: action.payload };

    case "SAVE_SALE_PRODUCTS":
      return { ...state, saleProducts: action.payload };
    case "SET_SALE_PRODUCTS_LOADING":
      return { ...state, isSaleProductsLoading: action.payload };
    case "SET_HIDEN_SALE_PRODUCTS_LOADING":
      return { ...state, isHiddenSaleProductsLoading: action.payload };

    case "SAVE_CATEGORY_FILTER_TYPE":
      return { ...state, categoryFilterType: action.payload };

    default:
      return state;
  }
}
